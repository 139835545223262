@import 'src/styles/variables.scss';
@import 'src/styles/utils.scss';

.dropdownBtn {
  border: none !important;
  pointer-events: all;
}

.bmItem {
  display: inline-block;
  margin-bottom: 10px;
  color: $white;
  font-size: 1.3em;

  /* Our sidebar item styling */
  text-decoration: none;

  transition: color 0.2s;
}

.borderBottom {
  border-bottom: 1px solid $white;
}