@import 'src/styles/variables';
@import 'src/styles/utils';

.page-footer {
  pointer-events: none;
  position: fixed;
  bottom: 10px;

  display: flex;
  align-items: end;
  width: 100%;
  padding-bottom: px-to-rem(50px) !important;

  background: transparent !important;

  @include set-media(lg) {
    padding: px-to-rem(30px 40px) !important;
  }

  @include set-media(md) {
    padding: px-to-rem(15px 15px) !important;
  }

  @include set-media(sm) {
    padding: px-to-rem(5px 5px) !important;
  }
}
