@import 'src/styles/variables';
@import 'src/styles/utils';

.contact-form {
  margin: px-to-rem(25px) 0;

  & .ant-form-item-label > label {
    color: $white;
  }

  & .text-area {
    resize: none;
  }

  & .ant-form-item-control-input input,
  & .text-area {
    border-color: $primary;
    border-radius: px-to-rem(15px);
  }

  & .submit-item {
    display: flex;
    justify-content: center;
  }

  & .send-btn {
    min-width: 50%;
  }
}

.submittedMessage {
  margin: px-to-rem(25px) 0;

  color: $primary;
}

.ant-form-item-explain-error {
  color: $tertiary !important;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  border-color: $tertiary !important;
}

.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0 !important;
}
