@import 'src/styles/variables';
@import 'src/styles/utils';

.logo-svg {
  width: 100%;
  height: 100%;
}

.cls-1 {
  fill: $white;
}

.cls-2 {
  fill: $primary;
}

.cls-3 {
  fill: $tertiary;
}
