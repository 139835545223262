@import 'src/styles/variables.scss';
@import 'src/styles/utils.scss';

.switcher-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: px-to-rem(50px);
  height: px-to-rem(50px);
  margin-top: px-to-rem(30px);

  background: none;
  border: px-to-rem(3px) solid $white;
  border-radius: 50%;
  cursor: pointer;

  @include set-media(lg) {
    width: px-to-rem(45px);
    height: px-to-rem(45px);
    margin-top: px-to-rem(20px);
  }

  @include set-media(md) {
    width: px-to-rem(40px);
    height: px-to-rem(40px);
    margin-top: px-to-rem(18px);

    border-width: 2px;
  }

  @include set-media(sm) {
    width: px-to-rem(35px);
    height: px-to-rem(35px);
    margin-top: px-to-rem(16px);
  }
}

.switcher-btn:focus,
.switcher-btn:hover {
  background: $primary;
  border-color: $primary;
}

.view-bg {
  background: $black;

  & .view-text {
    margin-left: 15px;

    color: $white;
    font-weight: bold;
  }
}

.view-bg:focus,
.view-bg:hover {
  background: $primary !important;
}
