@import 'variables';

@function px-to-rem($list) {
  $result: ();

  @each $value in $list {
    $result: append($result, (($value / $rem-base) * 1rem));
  }

  @return #{$result};
}

@mixin set-typography(
  $font-size,
  $line-height: $font-size,
  $font-weight: normal,
  $text-transform: inherit
) {
  font-weight: $font-weight;
  font-size: px-to-rem($font-size);
  line-height: px-to-rem($line-height);
  text-transform: $text-transform;
}

@mixin set-media($type) {
  @media (max-width: map-get($breakpoints, $type)) {
    @content;
  }
}
