@import 'src/styles/variables';
@import 'src/styles/utils';

.dt-button {
  color: $white;

  background-color: $primary;
  border-color: $primary;
  border-radius: px-to-rem(15px);
}

.dt-button:hover,
.dt-button:focus {
  color: $black;

  border-color: $primary;
}
