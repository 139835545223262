@import 'src/styles/variables';
@import 'src/styles/utils';

.loader {
  width: px-to-rem(65px);
  height: px-to-rem(65px);

  margin: auto;

  background: url('../../../assets/gifs/loading.gif') center no-repeat;
  background-size: contain;
}
