@import "utils";

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.bg-white {
  background-color: $white;
}

.bg-primary {
  background-color: $primary;
}

.bg-tertiary {
  background-color: $tertiary;
}

.bg-gray {
  background-color: $gray;
}

.bg-black {
  background-color: $black;
}

.color-white {
  color: $white !important;
}

.color-black {
  color: $black !important;
}

.color-primary {
  color: $primary !important;
}

.color-secondary {
  color: $secondary !important;
}

.color-tertiary {
  color: $tertiary !important;
}

.text-center {
  text-align: center;
}

.z-0 {
  z-index: 0;
}

.z-100 {
  z-index: 100;
}

.z-1000 {
  z-index: 1000;
}

.d-none {
  display: none;
}

.block-content {
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > * {
    flex: auto;
  }
}

.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.margin-auto {
  margin: auto;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.additional-text {
  font-size: 0.7em;
  opacity: 0.8;
}

.gaps {
  column-gap: 10px !important;
  row-gap: 10px !important;
}

.gaps-2 {
  column-gap: 20px !important;
  row-gap: 20px !important;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 10px;
}

.p-2 {
  padding: 20px;
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.pos-sticky {
  position: sticky;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-hidden {
  overflow: hidden;
}

.change-block-btn {
  width: 25%;

  margin-top: px-to-rem(10px);
  margin-right: auto;
  margin-left: auto;

  color: $tertiary;

  background: none;
  border: 1px solid $tertiary;
  border-radius: px-to-rem(15px);
  cursor: pointer;

  &:disabled {
    border-color: $gray;
  }
}

.change-block-btn:hover,
.change-block-btn:focus {
  color: $primary;

  border-color: $primary;
}

.chip {
  border-radius: 30px;
  padding: 2px 10px;
}

.font-scale {
  font-size: 1.5vmax;
  line-height: 1.5vmax;

  @include set-media(sm) {
    font-size: 0.8em;
    line-height: inherit;
  }
}