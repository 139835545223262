@import 'src/styles/variables';
@import 'src/styles/utils';

.svg-icon {
  width: px-to-rem(35px);
  height: px-to-rem(35px);

  @include set-media(lg) {
    width: px-to-rem(30px);
    height: px-to-rem(30px);
  }

  @include set-media(md) {
    width: px-to-rem(25px);
    height: px-to-rem(25px);
  }

  @include set-media(sm) {
    width: px-to-rem(20px);
    height: px-to-rem(20px);
  }

  & .svg-icon-path {
    fill: $white;
  }
}
