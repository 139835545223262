@import 'styles/variables';
@import 'styles/utils';
@import 'styles/fonts';
@import 'atropos/atropos.scss';
@import 'swiper/css/bundle';

* {
  box-sizing: border-box;
}

html {
  background-color: $black;
}

body {
  color: $white;
  touch-action: pan-x pan-y;
  font-family: StaromoskovskyRegular, sans-serif;
  @include set-typography($rem-base, 24px);
}

body,
h1,
h2,
h3,
p,
ul,
button {
  margin: 0;
  padding: 0;
}
