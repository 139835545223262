@import 'src/styles/variables';
@import 'src/styles/utils';

.nav-link {
  position: relative;

  & .nav-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: px-to-rem(50px);
    height: px-to-rem(50px);

    border: px-to-rem(3px) solid $white;
    border-radius: 50%;

    @include set-media(lg) {
      width: px-to-rem(45px);
      height: px-to-rem(45px);
    }

    @include set-media(md) {
      width: px-to-rem(40px);
      height: px-to-rem(40px);

      border-width: 2px;
    }

    @include set-media(sm) {
      width: px-to-rem(35px);
      height: px-to-rem(35px);
    }
  }

  .nav-btn:hover,
  .nav-btn:focus {
    background: $primary;
    border-color: $primary;
  }
}
